import React from "react";
import { Layout, SEO } from "../layout";
import ChangeBackground from "../components/ChangeBackground";


const SecondPage = () => (
	<Layout>
		<SEO title="Page two" />
		<ChangeBackground />
	</Layout>
);

export default SecondPage;

